import React, { useEffect, useRef } from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import get from 'lodash/get';

import { Button, Img } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';

import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';

interface Props {
  article: ArticleLink | FeatureArticlePageLink;
  variant: 'small' | 'medium' | 'large';
  className?: string;
  hideSectionIcon?: boolean;
  isSplitLayout?: boolean;
  isCarouselTeaser?: boolean;
}

const ImageTextStoryTeaserWidget: React.FC<Props> = ({
  article,
  variant,
  className = '',
  hideSectionIcon,
  isSplitLayout,
  isCarouselTeaser,
}) => {
  const { heroImage } = article;
  const image = article.featuredImage;
  const title = get(article, 'title', '');
  const brief = get(article, 'brief');
  const dek = get(article, 'dek', '');
  const authors = get(article, 'authors', []);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const isFiction = get(article, 'isFiction', false);
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const hasSection = 'section' in article && article.section !== undefined;
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  useEffect(() => {
    const handleResize = () => {
      if (imageContainerRef.current && isCarouselTeaser) {
        // Set max height to width of image
        const width = imageContainerRef.current.offsetWidth;

        imageContainerRef.current.style.maxHeight = `${width}px`;

        const anchorTag = imageContainerRef.current.querySelector('a');
        if (anchorTag) {
          anchorTag.style.maxHeight = `${width}px`;
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [imageContainerRef, isCarouselTeaser]);

  return (
    <div
      className={cx(
        `ImageTextStoryTeaserWidget ImageTextStoryTeaserWidget--${variant} teaser-widget-container color-black flex flex-col`,
        className,
        {
          'split-layout ImageTextStoryTeaserWidget--split-layout':
            isSplitLayout,
          'fiction-view fiction-view--more-padded': isFiction,
        }
      )}
    >
      <div
        className={cx('ImageTextStoryTeaserWidget__container', {
          'text-center': variant === 'large' || variant === 'medium',
          'text-left': variant === 'small',
        })}
      >
        <div
          className="ImageTextStoryTeaserWidget__container__image radius-xs"
          ref={imageContainerRef}
        >
          {image && image.src ? (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={linkTo}
              className="h100 w100"
              containerClassName={cx(
                'ImageTextStoryTeaserWidget__image relative w100 ',
                {
                  'fiction-teaser-img': isFiction,
                  'carousel-teaser-img': isCarouselTeaser,
                }
              )}
              wrap={true}
            >
              <Img
                className="grayscale-img w100 radius-xs"
                alt={image.alt || image.caption || ''}
                src={sanityImgUtil(image, 1080)}
                srcSet={sanityImgSrcSetUtil(image, 320, 768, 1080)}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                dimensions={image.metadata && image.metadata.dimensions}
                crop={image.crop}
              />
              <Img
                className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                alt={image.alt || image.caption || ''}
                src={sanityImgUtil(image, 1080)}
                srcSet={sanityImgSrcSetUtil(image, 320, 768, 1080)}
                dimensions={image.metadata && image.metadata.dimensions}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                crop={image.crop}
              />
            </Button>
          ) : (
            heroImage &&
            heroImage.src && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: title,
                })}
                to={linkTo}
                className="h100 w100"
                containerClassName={cx(
                  'ImageTextStoryTeaserWidget__image relative w100',
                  {
                    'fiction-teaser-img': isFiction,
                    'carousel-teaser-img': isCarouselTeaser,
                  }
                )}
                wrap={true}
              >
                <Img
                  className="grayscale-img w100 radius-xs"
                  alt={heroImage.alt || heroImage.caption || ''}
                  src={sanityImgUtil(heroImage, 1080)}
                  srcSet={sanityImgSrcSetUtil(heroImage, 320, 768, 1080)}
                  sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                  dimensions={
                    heroImage.metadata && heroImage.metadata.dimensions
                  }
                  crop={heroImage.crop}
                />
                <Img
                  className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                  alt={heroImage.alt || heroImage.caption || ''}
                  src={sanityImgUtil(heroImage, 1080)}
                  srcSet={sanityImgSrcSetUtil(heroImage, 320, 768, 1080)}
                  dimensions={
                    heroImage.metadata && heroImage.metadata.dimensions
                  }
                  sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                />
              </Button>
            )
          )}
        </div>
        <div className="ImageTextStoryTeaserWidget__container__content">
          <div>
            {hasSection && article.section && (
              <SectionButton
                className="ImageTextStoryTeaserWidget__section-button"
                section={article.section}
                hideIcon={hideSectionIcon || !get(article.section, 'title')}
                isFictionTeaser={isFiction}
              />
            )}
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={linkTo}
              wrap={true}
              containerClassName="block-important"
              className={cx(
                'ImageTextStoryTeaserWidget__title-button itc-cushing color-black',
                {
                  'font-600': variant === 'small',
                  'text-center md:mx1':
                    variant === 'large' || variant === 'medium',
                }
              )}
            >
              <span
                className={cx('ImageTextStoryTeaserWidget__title', {
                  'linked-border-bottom-sm-on-hover-red': variant === 'small',
                  'linked-border-bottom-lg-on-hover-red':
                    variant === 'medium' || variant === 'large',
                })}
              >
                {curlyQuotes(title)}
              </span>
            </Button>
            {(brief || dek) && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: title,
                })}
                to={linkTo}
                containerClassName="block-important"
                wrap={true}
              >
                <p
                  className={cx(
                    'ImageTextStoryTeaserWidget__dek color-gray-darker graebenbach font-400',
                    {
                      'text-center':
                        variant === 'large' || variant === 'medium',
                      'text-left': variant === 'small',
                      'carousel-teaser-dek': isCarouselTeaser,
                    }
                  )}
                >
                  {curlyQuotes(!!brief ? brief : dek)}
                </p>
              </Button>
            )}
            <AuthorButtons
              className={cx('ImageTextStoryTeaserWidget__authors', {
                'text-center': variant === 'large' || variant === 'medium',
              })}
              authors={authors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageTextStoryTeaserWidget;
