import React, { Component } from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import generateArticleUrl from 'utils/generateArticleUrl';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { Button, Img } from 'components/base';

import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink, Image } from 'sharedTypes';
import { BlockContent } from 'components/BlockContent';
import get from 'lodash/get';

interface Props {
  article: ArticleLink | FeatureArticlePageLink;
  className?: string;
}

const ImageDetail: React.FC<{ image: Image }> = ({ image }) => (
  <div className="ImageLightboxStoryTeaserWidget__image__details">
    {image.caption && (
      <span className="ImageLightboxStoryTeaserWidget__image__caption mr1 color-off-white ">
        {curlyQuotes(image.caption)}
      </span>
    )}
    {image.rteCredit ? (
      <span className="ImageCreditLink ImageLightboxStoryTeaserWidget__image__credit uppercase color-white transition-shorter">
        <BlockContent blocks={image.rteCredit} />
      </span>
    ) : image.credit ? (
      <span className="ImageLightboxStoryTeaserWidget__image__credit uppercase color-white">
        {curlyQuotes(image.credit)}
      </span>
    ) : (
      ''
    )}
  </div>
);

class ImageLightboxStoryTeaserWidget extends Component<Props> {
  render() {
    const { article, className = '' } = this.props;
    const { title, featuredImage: image, heroImage } = article;
    const isFiction = get(article, 'isFiction', false);
    const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
    const hasSection = 'section' in article && article.section !== undefined;
    const linkTo =
      hasSection && !isFeatureArticle
        ? generateArticleUrl(article as ArticleLink)
        : `/feature/${article.slug}`;

    return (
      <div
        className={cx(
          `ImageLightboxStoryTeaserWidget flex flex-col`,
          className,
          { 'fiction-view fiction-view--more-padded': isFiction }
        )}
      >
        {image && image.src ? (
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: title,
            })}
            to={linkTo}
            className="h100 w100"
            containerClassName="ImageLightboxStoryTeaserWidget__image relative w100 graebenbach font-400"
            wrap={true}
          >
            <Img
              className="grayscale-img w100 radius-xs"
              alt={image.alt || image.caption || ''}
              src={sanityImgUtil(image, 1920)}
              srcSet={sanityImgSrcSetUtil(image, 320, 768, 1080, 1920)}
              sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
              dimensions={image.metadata && image.metadata.dimensions}
              crop={image.crop}
            />
            <Img
              className="color-img absolute t0 r0 l0 w100 radius-xs"
              alt={image.alt || image.caption || ''}
              src={sanityImgUtil(image, 1920)}
              srcSet={sanityImgSrcSetUtil(image, 320, 768, 1080, 1920)}
              dimensions={image.metadata && image.metadata.dimensions}
              sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
            />
            <ImageDetail image={image} />
          </Button>
        ) : (
          heroImage &&
          heroImage.src && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={linkTo}
              className="h100 w100"
              containerClassName="ImageLightboxStoryTeaserWidget__image relative w100 graebenbach font-400"
              wrap={true}
            >
              <Img
                className="grayscale-img w100 radius-xs"
                alt={heroImage.alt || heroImage.caption || ''}
                src={sanityImgUtil(heroImage, 1920)}
                srcSet={sanityImgSrcSetUtil(heroImage, 320, 768, 1080, 1920)}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                dimensions={heroImage.metadata && heroImage.metadata.dimensions}
                crop={heroImage.crop}
              />
              <Img
                className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                alt={heroImage.alt || heroImage.caption || ''}
                src={sanityImgUtil(heroImage, 1920)}
                srcSet={sanityImgSrcSetUtil(heroImage, 320, 768, 1080, 1920)}
                dimensions={heroImage.metadata && heroImage.metadata.dimensions}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
              />
              <ImageDetail image={heroImage} />
            </Button>
          )
        )}
      </div>
    );
  }
}

export default ImageLightboxStoryTeaserWidget;
