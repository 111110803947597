import React, { Component } from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import generateArticleUrl from 'utils/generateArticleUrl';
import get from 'lodash/get';

import { Button } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';

import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';

interface Props {
  className?: string;
  article: ArticleLink | FeatureArticlePageLink;
  variant: 'small' | 'medium' | 'large' | 'x-large';
  hideSectionIcon?: boolean;
  isSplitLayout?: boolean;
}

class TextStoryTeaserWidget extends Component<Props> {
  render() {
    const {
      className = '',
      article,
      variant,
      hideSectionIcon,
      isSplitLayout,
    } = this.props;
    const title = get(article, 'title', '');
    const isFiction = get(article, 'isFiction', false);
    const brief = get(article, 'brief');
    const dek = get(article, 'dek', '');
    const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
    const hasSection = 'section' in article && article.section !== undefined;
    const linkTo =
      hasSection && !isFeatureArticle
        ? generateArticleUrl(article as ArticleLink)
        : `/feature/${article.slug}`;

    return (
      <div
        className={cx(
          `TextStoryTeaserWidget TextStoryTeaserWidget--${variant} teaser-widget-container color-black flex flex-col`,
          className,
          {
            'justify-center': variant === 'small',
            'split-layout TextStoryTeaserWidget--split-layout': isSplitLayout,
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        <div
          className={cx('TextStoryTeaserWidget__container', {
            'text-center':
              variant === 'x-large' ||
              variant === 'large' ||
              variant === 'small',
            'text-left': variant === 'medium',
            'md:mx1_5': variant === 'x-large' || variant === 'large',
          })}
        >
          {hasSection && article.section && (
            <SectionButton
              className="TextStoryTeaserWidget__section-button"
              section={article.section}
              hideIcon={hideSectionIcon || !get(article, 'section.title')}
              isFictionTeaser={isFiction}
            />
          )}
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: title,
            })}
            to={linkTo}
            className="h100 w100 color-black"
            containerClassName={cx(
              'TextStoryTeaserWidget__title itc-cushing block-important',
              {
                'font-600': variant === 'small' || variant === 'medium',
                'font-300': variant === 'x-large' || variant === 'large',
              }
            )}
            wrap={true}
          >
            <span
              className={cx({
                'linked-border-bottom-sm-on-hover-red':
                  variant === 'small' || variant === 'medium',
                'linked-border-bottom-md-on-hover-red':
                  variant === 'x-large' || variant === 'large',
              })}
            >
              {curlyQuotes(title)}
            </span>
          </Button>
          {(!!brief || !!dek) &&
            (variant === 'medium' ||
              variant === 'large' ||
              variant === 'x-large') && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: title,
                })}
                to={linkTo}
                wrap={true}
                containerClassName="block-important"
              >
                <p
                  className={cx(
                    'TextStoryTeaserWidget__dek color-gray-darker graebenbach font-400',
                    {
                      'text-center':
                        variant === 'large' || variant === 'x-large',
                      'text-left': variant === 'medium',
                    }
                  )}
                >
                  {curlyQuotes(!!brief ? brief : dek)}
                </p>
              </Button>
            )}
          <AuthorButtons
            className={cx('TextStoryTeaserWidget__authors', {
              'TextStoryTeaserWidget__authors--small': variant === 'small',
            })}
            authors={get(article, 'authors', [])}
          />
        </div>
      </div>
    );
  }
}

export default TextStoryTeaserWidget;
