import React from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import generateArticleUrl from 'utils/generateArticleUrl';
import get from 'lodash/get';

import { Button } from 'components/base';
import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';

interface Props {
  className?: string;
  article: ArticleLink | FeatureArticlePageLink;
  isSplitLayout?: boolean;
}

const QuoteStoryTeaserWidget: React.FC<Props> = ({
  className = '',
  article,
  isSplitLayout,
}) => {
  const title = get(article, 'title', '');
  const quote = get(article, 'quote.quote', '');
  const attribution = get(article, 'quote.attribution', []);
  const isFiction = get(article, 'isFiction', false);
  const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
  const hasSection = 'section' in article && article.section !== undefined;
  const linkTo =
    hasSection && !isFeatureArticle
      ? generateArticleUrl(article as ArticleLink)
      : `/feature/${article.slug}`;

  return (
    <Button
      ariaLabel={Language.t('Global.articleButtonAriaLabel', {
        title: title,
      })}
      to={linkTo}
      className="w100 h100 flex"
      containerClassName="w100 h100"
      wrap={true}
    >
      <div
        className={cx(
          'QuoteStoryTeaserWidget teaser-widget-container flex flex-col justify-between color-black text-left',
          className,
          {
            'split-layout QuoteStoryTeaserWidget--split-layout': isSplitLayout,
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        <p className="QuoteStoryTeaserWidget__text graebenbach font-700 color-black pb4">
          {curlyQuotes(quote)}
        </p>
        <div className="flex flex-col">
          {attribution &&
            attribution.map((name: string) => (
              <p
                key={`${title}-${name}`}
                className="QuoteStoryTeaserWidget__attribution graebenbach font-400 color-gray-darker"
              >
                &mdash;{`${curlyQuotes(name)}`}
              </p>
            ))}
        </div>
      </div>
    </Button>
  );
};

export default QuoteStoryTeaserWidget;
