import React, { Component } from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import generateArticleUrl from 'utils/generateArticleUrl';
import get from 'lodash/get';

import { Button } from 'components/base';

import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';

interface Props {
  article: ArticleLink | FeatureArticlePageLink;
  className?: string;
  isSplitLayout?: boolean;
}

class FactStoryTeaserWidget extends Component<Props> {
  render() {
    const { article, className = '', isSplitLayout } = this.props;
    const { fact } = article;
    const isFiction = get(article, 'isFiction', false);
    const hasSection = 'section' in article && article.section !== undefined;
    const isFeatureArticle = get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
    const linkTo =
      hasSection && !isFeatureArticle
        ? generateArticleUrl(article as ArticleLink)
        : `/feature/${article.slug}`;

    return (
      <Button
        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
          title: get(article, 'title', ''),
        })}
        to={linkTo}
        className="w100 h100 flex"
        containerClassName="w100 h100"
        wrap={true}
      >
        <div
          className={cx(
            'FactStoryTeaserWidget teaser-widget-container color-black flex flex-col items-center justify-center text-center',
            className,
            {
              'split-layout FactStoryTeaserWidget--split-layout': isSplitLayout,
              'fiction-view fiction-view--more-padded': isFiction,
            }
          )}
        >
          {fact && (
            <>
              <p
                className={cx(
                  'FactStoryTeaserWidget__fact itc-cushing font-300 color-black'
                )}
              >
                {curlyQuotes(get(fact, 'fact', ''))}
              </p>
              <p className="FactStoryTeaserWidget__subtitle graebenbach color-gray-darker font-400">
                {curlyQuotes(get(fact, 'subtitle', ''))}
              </p>
            </>
          )}
        </div>
      </Button>
    );
  }
}

export default FactStoryTeaserWidget;
